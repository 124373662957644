import React, { useState, useRef, useEffect } from 'react';
import './frameNew.css';
import chatSoundIconWhite from '../../../static/img/chatSoundSpeaker.png'
import chatSpeakerMuteIcon from '../../../static/img/chatMuteSpeaker.png'
import chatBotAvatarGala from '../../../static/img/chatBotAvatarGala.png'
import chatBotAvatarDiana from '../../../static/img/chatBotAvatarDiana.png'
import { useParams } from 'react-router-dom';
import natashaGifCircle from '../../../static/img/circleGif/natashaGifCircle.gif'
import galaGifCircle from '../../../static/img/circleGif/galaGifCircle.gif'
import dianaGifCircle from '../../../static/img/circleGif/dianaGifCircle.gif'
import aubrey from '../../../static/img/circleGif/aubrey.jpg'
import geuilia from '../../../static/img/circleGif/geuilia.jpg'
import greenSendIcon from '../../../static/img/greenSendIcon3.png'
import {languages} from './languages'
import axios from "axios"
const SreeChatID = () => {
    const [showChatBot, setShowChatBot] = useState(true);
    const urlParams = useParams()
    const [isSoundOn, setIsSoundOn] = useState(true);
    const { companyName, chatId,  avatarName } = urlParams;
    const [hideChatInput, sethideChatInput] = useState(true)
    const [messages, setMessages] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [isRecordingTemp, setIsRecordingTemp] = useState(false);
    const messagesEndRef = useRef(null);
    const mediaRecorderRefTemp = useRef(null);
    const recordedChunksRefTemp = useRef([]);
    const mediaStreamRefTemp = useRef(null);
    const [recentBotIndex, setRecentBotIndex] = useState(null);
    const [recordMessage, setRecordMessage] = useState(false);
    const videoRef = useRef(null);
    const audioConstraintsTemp = {
        audio: {
            sampleRate: 16000,
            channelCount: 1,
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
        },
    };
    const isIOS = () => /iPhone|iPad|iPod/i.test(navigator.userAgent);
    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.includes('safari') && !ua.includes('chrome');
    };
    useEffect(() => {
        const latestBotIndex = messages
            .map((item, index) => (item.type === 'bot' ? index : null))
            .filter(index => index !== null)
            .pop();
        if (latestBotIndex !== null) {
            setRecentBotIndex(latestBotIndex);
        }
    }, [messages]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setRecordMessage(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, [recordMessage]);
    const audioRef = useRef(null);
    const base64ToBlob = (base64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };
    
    const sendAudioToApi = async () => {
        if (!isRecordingTemp) {
            try {
                mediaStreamRefTemp.current = await navigator.mediaDevices.getUserMedia(audioConstraintsTemp);
                const mimeType = isIOS() || isSafari() ? 'audio/mp4' : 'audio/webm';
                if (!MediaRecorder.isTypeSupported(mimeType)) {
                    console.warn(`${mimeType} is not supported on this browser. Falling back to a supported type.`);
                    return;
                }
                mediaRecorderRefTemp.current = new MediaRecorder(mediaStreamRefTemp.current, { mimeType });
                mediaRecorderRefTemp.current.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        recordedChunksRefTemp.current.push(event.data);
                    }
                };
                mediaRecorderRefTemp.current.onstop = async () => {
                    const blobTemp = new Blob(recordedChunksRefTemp.current, { type: mimeType });
                    recordedChunksRefTemp.current = [];
                    const readerTemp = new FileReader();
                    readerTemp.readAsDataURL(blobTemp);

                    readerTemp.onloadend = async () => {
                        setRecordMessage(true)
                        const base64AudioTemp = readerTemp.result.split(',')[1];
                        const formDataTemp = new FormData();
                        formDataTemp.append('audio_file', base64AudioTemp);
                        formDataTemp.append('file_extension', mimeType === 'audio/mp4' ? 'mp4' : 'webm');
                        try {
                            const responseTemp = await fetch(`https://speech.pranathiss.com/api/vad-base64-speech-to-text/`, {
                                method: 'POST',
                                body: formDataTemp,
                            });
                            const resultTemp = await responseTemp.json();
                            setRecordMessage(false)
                            callApiOnPageLoad(resultTemp.response)
                        } catch (error) {
                            console.error(`Error: ${error.message}`);
                        }
                    };
                };
                mediaRecorderRefTemp.current.start();
                setIsRecordingTemp(true);
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    console.error('Microphone access was denied. Please enable it in your settings.');
                } else if (error.name === 'NotFoundError') {
                    console.error('No microphone found. Please connect a microphone.');
                } else {
                    console.error(`An unexpected error occurred: ${error.message}`);
                }
            }
        } else {
            mediaRecorderRefTemp.current.stop();
            mediaStreamRefTemp.current.getTracks().forEach((track) => track.stop());
            setIsRecordingTemp(false);
        }
    };
    const toggleSound = () => {
        let PreisSoundOn = isSoundOn
        if (videoRef.current) {
            videoRef.current.muted = PreisSoundOn;

        }
        if (audioRef.current) {
            audioRef.current.muted = PreisSoundOn;
            
        }
        setIsSoundOn(!PreisSoundOn)
    };
    const apiBaseUrl = 'https://chatportal.pranathiss.com/qxbox/apiV1/chat-response-v1/'
    const [form_detailList, setform_detailList] = useState([])
    const [formCount, setformCount] = useState(0)
    const [audio_data, setaudio_data] = useState(false) 
    const [typingLoading, settypingLoading] = useState(false)
 
    const callApiOnPageLoad = async (data, check) => {
        setonloadTime(true)
        setInputValue("")
        setaudio_data(false)
        settypingLoading(true)
        let object = {
            isuser: true,
            message: data,
            text: data,
            custom_payloads: [],
            api_details:false,
            api_call:{},
            form_status:false,
            form_details:[]
        }

        if (check != "onloadtime") {
            let charts = messages
            charts.push(object)
            setMessages([...charts])
        }

        let charthistory21 = chatHistory
        charthistory21.push({ human: data })
        setChatHistory([...charthistory21])
        let req = {
            chatbot_id: chatId,
            user_id: companyName,
            chat_history: charthistory21,
            avatar_name : avatarName
        }
        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
        try {
            const response = await fetch(`${apiBaseUrl}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(req),
            });

            if (response.ok) {
                
                const data = await response.json();
                setaudio_data(true)
                if(data.form_status){
                    setformCount(0)
                    setform_detailList([...data.form_details])
                    sethideChatInput(false)
                }
                
                let object = {
                    isuser: false,
                    message: data.responses[0],
                    custom_payloads: data.custom_payloads,
                    api_details:data.api_details,
                    api_call:data.api_call,
                    form_status:data.form_status ? data.form_status : false,
                    form_details:data.form_status ? data.form_details[0] : []

                }
                
                
                let charts = messages
                charts.push(object)
                setMessages([...charts])
                let object2 = {
                    ai: data.responses[0]
                }

                let charthistory2s = chatHistory

                charthistory2s.push(object2)

                setChatHistory([...charthistory2s])
                setInputValue("")
                
                setTimeout(() => {
                    if (messagesEndRef.current) {
                        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
                    }
                }, 300);
                if(data.audio_data){
                    const audioBlob = base64ToBlob(data.audio_data, 'audio/mp3');
                    const audioUrl = URL.createObjectURL(audioBlob);
                    const audioElement = new Audio(audioUrl);
                    audioRef.current = audioElement;
                    audioElement.muted = !isSoundOn;
                    
                    
                    if (videoRef.current) {
                        videoRef.current.src = data.video_path
                        videoRef.current.muted = true
                        
                        try {
                            // Play both video and audio together
                            await Promise.all([videoRef.current.play(), audioElement.play()]);
                            if(videoRef.current){
                                videoRef.current.onended = () => {
                                    videoRef.current.src = `https://d1duq3wrgv494c.cloudfront.net/static/${avatarName}/${avatarName}_static.mp4`;
                                    videoRef.current.muted = true;
                                    videoRef.current.play();
                                }
                            }
                           
                          } catch (error) {
                            console.error('Autoplay failed:', error);
                          }
                    }
                } else if(data.video_path){
                    if (videoRef.current) {
                        videoRef.current.src = data.video_path
                        videoRef.current.muted = !isSoundOn
                        videoRef.current.play();

                        if(videoRef.current){
                            videoRef.current.onended = () => {
                                videoRef.current.src = `https://d1duq3wrgv494c.cloudfront.net/static/${avatarName}/${avatarName}_static.mp4`;
                                videoRef.current.muted = true;
                                videoRef.current.play();
                            }
                        }
                    }
                   // setvideo_path(data.video_path)
                }
                settypingLoading(false)


            } else {
                console.error('API error:', response.statusText);
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if(inputValue){
            callApiOnPageLoad(inputValue);
        }
        
    }
    const [onloadTime, setonloadTime] = useState(false)
 
    const [inputvaluesName, setinputvaluesName] = useState()
    const [validation, setvalidation] = useState("")
    const inputChangeTosubmit = (data, id, type, index) => {
        setvalidation("")
        let isValid = true
        const emailvalue = data.trim();
        let charts = messages
        charts[index].validation = ""
        setMessages([...charts])
        if(type == "Emailfield" && emailvalue){
            if (!/^\S+@\S+\.\S+$/.test(emailvalue)) {
                let charts = messages
                charts[index].validation = "Please enter a valid email"
                setMessages([...charts])
                isValid = false;
            }
        }

        if(type == "Integerfield" && emailvalue){
            if (!/^\d{9,12}$/.test(emailvalue)) {
                let charts = messages
                charts[index].validation = "Please enter a valid phone number (9 to 12 digits)."
                 
                setMessages([...charts])
                isValid = false;
            }
        }
        setinputvaluesName(data)
    }
    const handleInputFormSubmit = (e, api_details, number, type, index ) => {
        e.preventDefault();
        setvalidation("")
        if(type == "Emailfield" && inputvaluesName){
            if (!/^\S+@\S+\.\S+$/.test(inputvaluesName)) {
                let charts = messages
                charts[index].validation = "Please enter a valid email"
                console.log(index)
                setMessages([...charts])
                isValid = false;
                return false
            }
        }

        if(type == "Integerfield" && inputvaluesName){
            if (!/^\d{9,12}$/.test(inputvaluesName)) {
                let charts = messages
                charts[index].validation = "Please enter a valid phone number (9 to 12 digits)."
                console.log(index)
                setMessages([...charts])
                isValid = false;
                return false
            }
        }

        let charts = messages
        let object = {
            isuser: true,
            message: inputvaluesName,
        }
        charts.push(object)
        setMessages([...charts]);
        let subtotal = number + 1
        charts[index].value =  inputvaluesName
        setMessages([...charts])

        if(subtotal < form_detailList.length){
            let object2 = {
                isuser: false,
                message: "",
                form_status: true,
                api_details:api_details,
                api_call:true,
                form_details:form_detailList[number+1],
            }
            charts.push(object2)
            setMessages([...charts]);
            setformCount(number+1)
        }else{
            let chatslist = messages;
            let array = []
            chatslist.map((chat, i)=>{
                console.log(chat)
                if(chat.form_status && chat.form_details){
                    let obj = {
                        name:chat.value,
                        label: chat.form_details.type
                    }

                    array.push(obj);
                }
            })
            //axios.defaults.headers.common = { 'Authorization': `Bearer ${sessionStorage.getItem("Admintoken")}` }
            if(api_details.method == "POST"){
                axios.post(api_details.url, JSON.stringify(array) ).then((rep) => {
                    
                }).catch((e) => {
    
                })
            }else{
                axios.post(api_details.url, JSON.stringify(array) ).then((rep) => {
                    
                }).catch((e) => {
    
                })
            }
            
            
        }
        setTimeout(() => {
            if (messagesEndRef.current) {
                messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }
    return (
        <>
            
                <div className={!showChatBot ? "videochartvalue  " : "videochartvalue "}>
                    <div className={!showChatBot ? 'container' : "dnone"} id='ChatBotContainer'>
                        <div className='headAndOutput'>
                            <div className='chatHead'>
                                <div className='headIcontext'>
                                    <div className='group-2'></div>
                                    <div className='iconHeadChat'>
                                        <img src={avatarName == 'natasha' ? "/img/chatAvatarIcon3.png" : avatarName == 'gala' ? chatBotAvatarGala : avatarName == 'diana' ? chatBotAvatarDiana : avatarName == 'aubrey' ? aubrey : avatarName == 'geuilia' ? geuilia : chatBotAvatarDiana} alt="Avatar" />
                                        <div className='headTextClass'>
                                            <div className="text-wrapper miniHead">Chat with</div>
                                            <div className="text-wrapper-2">AI Assistant</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='headMinClose'>
                                    <div className=' speakerIconButton' >
                                        <img src={isSoundOn ? chatSoundIconWhite : chatSpeakerMuteIcon} alt="Speaker" onClick={toggleSound} />
                                        <img src="/img/minimizeIcon/minimize1.png" alt="Line" onClick={() => {setShowChatBot(!showChatBot);}} />
                                    </div>
                                </div>
                            </div>
                            <div id="output-container">
                                <div className="messages-container">
                                    {messages.map((item, index) => (
                                        <div key={index} className={`message-item ${item.isuser ? 'sender' : 'receiver'}`}>
                                            <div className='senderIconText'>

                                                <div className="message">
                                                    {recordMessage && index === recentBotIndex ? (
                                                        <div className="chat-bubble-audio">
                                                            <div className="typing">
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                            </div>
                                                        </div>
                                                    ) : 
                                                    <>
                                                    <p>{item.message}</p> {/* Display data.responses[0] */}

                                                    {item.form_status == false &&  item?.custom_payloads?.length !==0 && item?.custom_payloads?.map((elem, index) => (
                                                        <div className='response-options'>
                                                            <button onClick={()=>callApiOnPageLoad(elem.name) } type='button'>  {elem.name} </button>
                                                        </div>
                                                    ))}
                                                    {item.form_status ? 
                                                        <>
                                                            <form onSubmit={(event) => handleInputFormSubmit(event, item.api_details, formCount, item.form_details.type ? item.form_details.type : "Charfield", index )} className='formSingleFields'>
                                                                <div className="formInputError">
                                                                    <input
                                                                        className="formInputNamePhone"
                                                                        type="text"
                                                                        name="name"
                                                                        onChange={(e)=>inputChangeTosubmit(e.target.value, formCount, item.form_details, index)}
                                                                        placeholder={item.form_details.label}
                                                                        
                                                                    />
                                                                    <span id="nameError" className="contactFormError">{item.validation}</span>
                                                                </div>
                                                                <button type="submit">
                                                                    <img type="submit" src={greenSendIcon} alt="Send" />
                                                                </button>
                                                            </form>  
                                                        </>
                                                    :""}
                                                </>
                                                }
                                                
                                                
                                                </div>
                                                {item.isuser && <img src="/img/chatSenderIcon.png" alt="Sender Avatar" />}
                                                {item.isuser === false && <img src={avatarName == 'natasha' ? "/img/chatAvatarIcon3.png" : avatarName == 'gala' ? chatBotAvatarGala : avatarName == 'diana' ? chatBotAvatarDiana : avatarName == 'aubrey' ? aubrey : avatarName == 'geuilia' ? geuilia:chatBotAvatarDiana} alt="Receiver Avatar" />}
                                            </div>
                                            <div ref={messagesEndRef} />
                                        </div>
                                    ))}
                                    {/* {messages.length == 0 && (
                                        <div className="chat-bubble-audio">
                                            <div className="typing">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    )} */}
                                    {recordMessage && (
                                        <div className="chat-bubble-audio">
                                            <div className="typing">
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                                <div className="dot"></div>
                                            </div>
                                        </div>
                                    )}
                                    {typingLoading ? <div className="chat-bubble-audio">
                                                            <div className="typing">
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                                <div className="dot"></div>
                                                            </div>
                                                        </div> : ""}
                                    <div ref={messagesEndRef} />
                                </div>
                                <div className="hello-container">
                                    <video className="staticVideoClass" width="100%" autoPlay ref={videoRef}  >
                                         
                                    </video>
                                </div>
                            </div>
                        </div>
                        {hideChatInput ? 
                        <div className='outputHelpfulClass'>
                            <form onSubmit={handleFormSubmit} className="input-container">
                                <input
                                    type="text"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="Enter your message"
                                    disabled={loading}
                                />
                                {isRecordingTemp ? (
                                    <div className="recording-indicator">
                                        <div onClick={sendAudioToApi} disabled={loading} className='iconButton'>
                                            <img src="/img/chatMicSpeakIcon.png" alt="Mic" />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='iconButton' onClick={sendAudioToApi} disabled={loading}>
                                        <img src="/img/chatMicIcon.png" alt="Mic" />
                                    </div>
                                )}
                                <button type='submit' className='iconButton' disabled={loading} style={{ background: "none", padding: 0 }}>
                                    <img src="/img/chatSendIcon.png" alt="Send" />
                                </button>
                            </form>
                        </div> : ""}
                    </div>
                </div >
               
                <div className= {!showChatBot ? 'dnone' : " chatBoxImageClass"} >
                    <img src={avatarName == 'natasha' ? natashaGifCircle : avatarName == 'gala' ? galaGifCircle : avatarName == 'diana' ? dianaGifCircle : avatarName == 'aubrey' ? aubrey : avatarName == 'geuilia' ? geuilia :  dianaGifCircle} alt="" onClick={() => {setShowChatBot(!showChatBot); !onloadTime ? callApiOnPageLoad("hi", 'onloadtime') : "";}} />
                </div>
           
        </>
    );
}

export default SreeChatID;