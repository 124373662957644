import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; 

import PageNotFound from './screens/Frame/pagenotfound'
import NoEnglishAudio from "./screens/Frame/noAudioEnglish";
import FormInputBot from "./screens/Frame/formInputBot";
import BotSingleForm from "./screens/Frame/formInputSingle";
import FormInputSingle from "./screens/Frame/formInputSingle";
import ChatBotId from "./screens/Frame/chatBotId";
import SreeChatID from "./screens/Frame/SreeChatID";
const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);

root.render(
  <Router>
      <Routes>         
        <Route path="/:companyName/:chatId/:moduleName/:avatarName" exact={true} element={<SreeChatID />} />     
        <Route path="*" element={ <PageNotFound /> } />         
      </Routes>
    </Router>
);
